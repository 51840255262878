import { logSentryError } from './logSentryError';
import { getDbwTrackingParams } from './get-dbw-tracking-params';
import { getClinicalKeywordSearched } from '../direct-booking/utils';

/** Appends params to book_online_url and returns the relative url.
 *  @example `https://www.example-pmc-url.com/book/1234?q=test` => `/book/1234?q=test`
 *  Purpose:
 *    1. Move toward allowing client-side transitions to/from directbook
 *    2. Improve SEO
 *    3. Improve dev environment and allow end-to-end testing
 * @param  {Object} provider - provider which contains the absolute direct book url and provider id
 * @param  {Object} config - customer service config
 * @param  {{consumerTrackingToken?: string,userToke?: string,searchToken?: string,bcsToken?: string}} tokens
 * @param  {{search: string}} location - react-router location object
 * @param  {Object} apptOptions - optional extra key/value params to pass into DBW
 * @returns {String} returns a relative direct book url with appended url params from apptOptions
 */
export function getRelativeParameterizedBookingUrl(
  provider,
  config,
  tokens,
  location,
  apptOptions = {}
) {
  if (provider.book_online_url == null) {
    // book_online_url will be null for providers NOT on Direct Booking,
    // Do not log sentry errors for this case, just pass through.
    return provider.book_online_url;
  }

  let absoluteBookOnlineUrl;
  try {
    absoluteBookOnlineUrl = new URL(provider.book_online_url);
  } catch (error) {
    logSentryError('Provider booking URL is an invalid URL', {
      bookOnlineUrl: provider.book_online_url,
      providerId: provider.id
    });
    return provider.book_online_url;
  }

  const { category, clinicalKeyword } = getClinicalKeywordSearched(location);

  if (config.domains.includes(absoluteBookOnlineUrl.hostname)) {
    const url = absoluteBookOnlineUrl;

    const paramsToAppend = {
      ...getDbwTrackingParams({ config, tokens }),
      ...(clinicalKeyword && { [category]: clinicalKeyword }),
      ...apptOptions
    };

    Object.entries(paramsToAppend).forEach(([key, val]) => {
      url.searchParams.append(key, val);
    });

    const relativizedBookingUrl = url.href.replace(
      absoluteBookOnlineUrl.origin,
      ''
    );
    return relativizedBookingUrl;
  }
  return provider.book_online_url;
}
