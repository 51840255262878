/**
 * Get query params to append to dbw /book urls
 * @param  {Object} config - customer service config
 * @param  {{consumerTrackingToken?: string,userToken?: string,searchToken?: string,bcsToken?: string}} tokens
 * @returns {Object} object with query params
 */
export function getDbwTrackingParams({ config, tokens = {} }) {
  const { consumerTrackingToken, userToken, searchToken, bcsToken } = tokens;
  const { locale } = config;

  const params = {};

  if (consumerTrackingToken) {
    params.tt = consumerTrackingToken;
  }

  if (userToken) {
    params.ut = userToken;
  }

  if (searchToken) {
    params.st = searchToken;
  }

  if (locale === 'es-US') {
    params.locale = config.locale;
  }

  if (bcsToken) {
    params.bcs_token = bcsToken;
  }

  return params;
}
